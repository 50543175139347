import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    pairing: Palette['primary'];
  }
  interface PaletteOptions {
    pairing: PaletteOptions['primary'];
  }
}

const buttonDisabled = '#C5CBEB';
const errorInput = '#B30000';
const inputHover = '#3862F8';
const labelRequired = '#55547D';
const lightGreyText = '#767676';
const violineBlue = '#2A295C';
const sodexoBlue = '#283897';
const sodexoBlueFocussed = '#525EAB';
const sodexoBluePressed = '#212F76';
const sodexoGreen = '#3CDCC8';
const sodexoRed = '#E32B24';
const sodexoRedFocussed = '#FF616A';
const sodexoRedPressed = '#B30000';

export const getTheme = (): Theme =>
  createTheme({
    components: {
      MuiAvatar: {
        styleOverrides: {
          circular: {
            backgroundColor: '#3CDCC8',
            width: '32px',
            height: '32px',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            fontFamily: ['SansaPro-SemiBold', 'SansaPro', 'arial', 'sans-serif'].join(','),
            margin: '14px 12px',
            textTransform: 'uppercase',
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            textAlign: 'left',
          },
          asterisk: {
            color: errorInput,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-root': {
              overflow: 'unset',
            },
          },
          asterisk: {
            color: '#EA444E',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: lightGreyText,
            fontStyle: 'italic',
            fontSize: '16px',
            lineHeight: '16px',
            transformOrigin: 'left',
            '&.Mui-disabled': {
              color: labelRequired,
              fontStyle: 'normal',
            },
            '&.Mui-error': {
              color: labelRequired,
              fontStyle: 'normal',
            },
          },
        },
      },
      /**
       * Inputs
       */
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            width: '100%',
            borderRadius: 8,
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: errorInput,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: inputHover,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `3px solid ${inputHover}`,
            },
            '&.MuiOutlinedInput-adornedEnd': {
              paddingRight: 0,
            },
            '&.MuiInputBase-adornedEnd': {
              paddingRight: 0,
            },
          },
          input: {
            '&::placeholder': {
              color: lightGreyText,
              fontStyle: 'italic',
              opacity: 1,
            },
            '&:hover': {
              '&::placeholder': {
                color: inputHover,
              },
            },
            color: violineBlue,
            fontSize: 16,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '3px solid #3862F8',
              outlineOffset: '3px',
              borderRadius: '8px',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          underlineNone: {
            '&.Mui-focusVisible': {
              outline: '3px solid #3862F8',
              borderRadius: '8px',
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              outline: '3px solid #3862F8',
              padding: '15px 12px 15px 12px',
              borderRadius: '8px',
            },
          },
          positionEnd: {
            marginRight: 18,
            padding: '15px 12px 15px 12px',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: errorInput,
            fontStyle: 'italic',
            fontSize: 12,
            '&.Mui-error': {
              color: errorInput,
              fontStyle: 'italic',
              fontSize: 12,
            },
          },
        },
      },
      /**
       * Buttons
       */
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableRipple: true,
          disableTouchRipple: true,
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: {
            marginTop: 24,
            fontSize: 14,
            fontFamily: ['SansaPro-SemiBold', 'SansaPro', 'arial', 'sans-serif'].join(','),
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&.Mui-focusVisible': {
              outlineOffset: '3px',
              outline: '3px solid #3862F8',
            },
          },
          text: {
            textTransform: 'none',
            color: sodexoBlue,
          },
          contained: {
            width: '100%',
            height: 48,
            color: 'white',
            textTransform: 'none',
            borderRadius: 8,
            '&.Mui-disabled': {
              backgroundColor: buttonDisabled,
              color: 'white',
            },
            '&.Mui-focusVisible, &:hover': {
              backgroundColor: sodexoBlueFocussed,
            },
            '&:active': {
              backgroundColor: sodexoBluePressed,
            },
            '&.MuiButton-containedSecondary': {
              backgroundColor: sodexoRed,
              '&.Mui-focusVisible, &:hover': {
                backgroundColor: sodexoRedFocussed,
              },
              '&:active': {
                backgroundColor: sodexoRedPressed,
              },
            },
          },
          outlined: {
            width: '100%',
            height: 40,
            textTransform: 'none',
            borderRadius: 8,
            border: '2px solid',
            '&.Mui-focusVisible, &:hover': {
              backgroundColor: sodexoBlueFocussed,
              border: `2px solid ${sodexoBlueFocussed}`,
              color: 'white',
            },
            '&:active': {
              backgroundColor: sodexoBluePressed,
              border: `2px solid ${sodexoBluePressed}`,
              color: 'white',
            },
            '&.MuiButton-outlinedSecondary': {
              '&.Mui-focusVisible, &:hover': {
                backgroundColor: sodexoRedFocussed,
                border: `2px solid ${sodexoRedFocussed}`,
                color: 'white',
              },
              '&:active': {
                backgroundColor: sodexoRedPressed,
                border: `2px solid ${sodexoRedPressed}`,
                color: 'white',
              },
            },
          },
        },
      },
      /**
       * Select
       */
      MuiSelect: {
        styleOverrides: {
          outlined: {
            paddingRight: '14px!important',
            height: '1.4375em',
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableFocusRipple: true,
          disableRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            width: 42,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
          },
          input: {
            width: '100%',
            left: 0,
          },
          switchBase: {
            // Disable ripple
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&.Mui-checked': {
              transform: 'translateX(18px)',
              // Disable ripple
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '.MuiSwitch-thumb': {
                backgroundImage:
                  'url(\'data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.5073 4.44761C13.8124 4.72778 13.8326 5.20223 13.5524 5.50731L7.12383 12.5073C6.98223 12.6615 6.78264 12.7495 6.57331 12.75C6.36398 12.7505 6.16395 12.6635 6.02158 12.5101L2.45015 8.66007C2.16845 8.3564 2.18627 7.88186 2.48994 7.60015C2.79361 7.31845 3.26815 7.33627 3.54985 7.63994L6.56866 10.8942L12.4476 4.4927C12.7278 4.18762 13.2022 4.16743 13.5073 4.44761Z" fill="%237F7F9D"/></svg>\')',
              },
              // track - checked
              '&+.MuiSwitch-track': {
                opacity: 1,
                backgroundColor: sodexoGreen,
              },
            },
          },
          thumb: {
            backgroundColor: 'white',
            backgroundImage:
              'url(\'data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.00409 7.14399L11.9247 3.22128C12.1861 2.95956 12.5627 2.91271 12.8241 3.17443C13.0854 3.43615 13.0363 3.8102 12.7749 4.07192L8.85448 7.99484L12.7803 11.9228C13.0417 12.1845 13.0862 12.5628 12.8248 12.8245C12.5634 13.0862 12.1857 13.0418 11.9243 12.7801L7.99808 8.85171L4.07486 12.777C3.8135 13.0387 3.43963 13.0875 3.17827 12.8258C2.91691 12.5641 2.95443 12.1967 3.21579 11.9349L7.14768 8.00086L3.26162 4.10943C3.00026 3.84771 2.95438 3.47186 3.21574 3.21014C3.47711 2.94842 3.85545 2.99254 4.11681 3.25426L8.00409 7.14399Z" fill="%237F7F9D"/></svg>\')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          },

          track: {
            backgroundColor: '#7F7F9D',
            backgroundImage: '',
            opacity: 1,
          },
          sizeMedium: {
            '& .MuiSwitch-thumb': {
              width: 18,
              height: 18,
              transform: 'translateX(-6px) translateY(1px)',
            },
            '& .MuiSwitch-track': {
              borderRadius: 12,
              width: 42,
              height: 24,
            },
          },
        },
      },
      /**
       * Menu
       */
      MuiPaper: {
        styleOverrides: {
          root: {
            border: '1px solid #EAE9EF',
            boxShadow: '0px 4px 10px rgba(133, 133, 154, 0.3)!important',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 16,
            color: violineBlue,
            paddingTop: 12,
            paddingBottom: 12,
            borderBottom: '1px solid #EAE9EF',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'rgba(40, 56, 151, 0.08)',
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: 10,
            marginBottom: 10,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          container: {
            backgroundColor: 'rgba(42, 41, 92, 0.9)',
          },
          paper: {
            border: 0,
            bordeRadius: 8,
            padding: '24px 18px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: violineBlue,
            fontFamily: 'SansaPro-SemiBold',
            fontSize: 24,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: violineBlue,
            fontSize: 16,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    },
    palette: {
      primary: {
        main: sodexoBlue,
      },
      secondary: {
        main: sodexoRed,
        light: sodexoRedFocussed,
        dark: sodexoRedPressed,
        contrastText: '#fff',
      },
      // Adding custom color will be usable in next version of MUI
      pairing: {
        main: '#ffb300',
      },
    },
    typography: {
      fontFamily: ['SansaPro', 'arial', 'sans-serif'].join(','),
      fontSize: 16,
    },
  });
