import { datadogRum } from '@datadog/browser-rum';
import { FormControl, MenuItem, NoSsr, Select, SelectChangeEvent, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import locales from '../../../../configuration/locales.json';
import { ReactComponent as IconChevron } from '../../../../public/images/Icon-Chevron-Header.svg';
import { ReactComponent as IconLocalBig } from '../../../../public/images/Icon-Locale-24x.svg';
import { ReactComponent as IconLocal } from '../../../../public/images/Icon-Locale.svg';
import { GlobalContentLocalizedContent } from '../../../services/localization/interfaces';

export interface ILocaleSwitcherProps {
  action?: string;
  isLocalOpen?: boolean;
  globalContent?: GlobalContentLocalizedContent;
  locale: string;
  noArrow?: boolean;
  realmLocales: string[];
  ruid?: string;
  onChange?: (evt: SelectChangeEvent<string>) => void;
  setLoader?: () => void;
}
const LanguageSwitcher = (props: ILocaleSwitcherProps): JSX.Element => {
  const { action, isLocalOpen, globalContent, locale, noArrow, realmLocales, ruid, onChange, setLoader } = props;
  const [isOpen, setIsOpen] = useState(!!isLocalOpen);
  const authorizedLang = locales.filter((loc) => realmLocales?.includes(loc.id));
  const defaultLocale = realmLocales?.filter((loc) => authorizedLang.some((al) => al.id === loc));
  const [userLocale, setLocale] = useState(locale || defaultLocale?.[0]);
  const form = React.useRef(null);
  const isDesktop = useMediaQuery('(min-width:813px)');

  const handleLocaleChange = (evt: any) => {
    const waitChangeLocale = new Promise<void>((resolve) => {
      setLocale(evt.target.value);
      resolve();
    });

    waitChangeLocale
      .then(() => {
        if (setLoader) {
          setLoader();
        }
        form.current?.submit();
      })
      .catch(() => {
        throw new Error('Error while changing the current local');
      });
  };

  return (
    <React.Fragment>
      {authorizedLang.length > 1 && (
        <form ref={form} autoComplete='off' action={action} method='post'>
          {ruid && <input type='hidden' readOnly name='_csrf' value={ruid} />}
          <input readOnly type='hidden' name='action' value='change-locale' />
          <FormControl className='input-locale'>
            <NoSsr>
              <Select
                IconComponent={() => null}
                renderValue={(value) => (
                  <div
                    className={`text ${noArrow ? ' list-solution MuiTypography-body1' : ''}`}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {!noArrow ? (
                      <IconLocal
                        role='none'
                        focusable='false'
                        aria-hidden='true'
                        fill='#283897'
                        style={{ marginRight: '9px' }}
                      />
                    ) : (
                      <IconLocalBig role='none' focusable='false' aria-hidden='true' style={{ marginRight: '12px' }} />
                    )}
                    {authorizedLang.find((lang) => lang.id === value)?.localName ||
                      authorizedLang.find((lang) => lang.id === value)?.englishName}
                    {!noArrow && (
                      <IconChevron
                        role='none'
                        focusable='false'
                        aria-hidden='true'
                        stroke='#283897'
                        style={{
                          marginLeft: `${isDesktop ? '18px' : '9.5px'}`,
                          transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
                        }}
                      />
                    )}
                  </div>
                )}
                id='select-change-language'
                aria-label={
                  isOpen
                    ? `${globalContent.change_language} ${globalContent.dropdown_expanded}`
                    : `${globalContent.change_language} ${globalContent.dropdown_collapsed}`
                }
                required={true}
                name='locale'
                value={authorizedLang.length === 1 ? authorizedLang[0].id : userLocale}
                onChange={onChange || handleLocaleChange}
                open={isOpen}
                onOpen={() => {
                  setIsOpen(true);
                  datadogRum.addAction('SLT Locale switcher is open');
                }}
                onClose={() => {
                  setIsOpen(false);
                  datadogRum.addAction('SLT Locale switcher is close');
                }}
                className={`select-input ${isOpen ? ' is-selected' : ''}`}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                {authorizedLang.map((language) => (
                  <MenuItem
                    aria-labelledby='change-language'
                    className='menu-item'
                    key={language.id}
                    value={language.id}
                    data-dd-action-name={'SLT ' + language.englishName + ' on Locale switcher'}
                  >
                    {language?.localName || language.englishName}
                  </MenuItem>
                ))}
              </Select>
            </NoSsr>
          </FormControl>
        </form>
      )}
    </React.Fragment>
  );
};

export default LanguageSwitcher;
