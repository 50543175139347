import { ThemeProvider } from '@mui/material';
import React from 'react';
import { getTheme } from '../styles/style.utils';

export default function LegacyTemplate({ children }: { children: React.ReactNode }): JSX.Element {
  const sodexoTheme = getTheme();

  return (
    <ThemeProvider theme={sodexoTheme}>
      <div id='legacy' className='background'>
        {children}
      </div>
    </ThemeProvider>
  );
}
